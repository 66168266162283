/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Layout, Flex, Tabs, Collapse } from 'antd';
import '../page/homepage/HomePage.css'
import '../page/DetailPage/styles.css'
import HeadNavi from '../page/homepage/components/MainNavi/Head/HeadNavi';
import Banner from '../page/homepage/components/Content/Banner';
import { Collapse_img, Dollar, Expand, Trash, arrow_left, arrow_right, leaf_1, leaf_2, reload, start } from '../components/imgExport';
import { useDispatch, useSelector } from 'react-redux';
import { items } from '../services/data';
import useLoading from '../hook/useLoading';
import useToast from '../hook/useToast';
import * as bettingApi from '../api/betting/bettingApi';
import * as homePageApi from '../api/homePage/homePageApi';
import * as homePage_V2Api from '../api/homePage/homePage_V2Api';
import { selectorUser, setUserMoney } from '../store/slices/userSlice';
import { itemMenu } from '../page/homepage/components/MainNavi/Tabs/TabsNaviV2';
import useThrottle from '../hook/useThrottle';
import { getPiceWin, getPiceWinStright } from '../until';
import ContinueBetting from '../components/Modal/ContinueBetting';
import DeleteAll from '../components/Modal/DeleteAll';
import NotEnoughModal from '../components/Modal/NotEnoughModal';
import ModalWarning from '../components/Modal/ModalWarning';
import ModalBets from '../page/homepage/components/ModalBets/ModalBets';
import { useLocation } from 'react-router-dom';
import useDebounce from '../hook/useDebounce';
import TotalStakeComponentV2 from '../page/homepage/components/TotalStake/TotalStakeV2';
import BetTabsSoccer from '../page/homepage/components/Content/Sidebar/BetTabSoccer';
import ModalBillSoccer from '../components/Modal/ModalBillSoccer';
import GameSoccer_V2 from '../page/homepage/components/Content/GameList/GameSoccer_V2';
import GameSoccerLive_V2 from '../page/homepage/components/Content/GameList/GameSoccerLive_V2';
import io from 'socket.io-client';
import { API_URL_SOCCER } from '../env';
import LazyLoad from 'react-lazyload';
import './style.css'
import EmtyBetSlip from '../components/EmtyBetSlip';
import DayTime_V2 from '../page/homepage/components/Content/GameList/dayTime_V2';
import HeadRight from '../page/homepage/components/MainNavi/Head/HeadRight';
import ModalConfirmBet from '../components/Modal/ConfirmBetModal';
var intervalPrematchTournament: any
var intervalPrematch: any
var intervalListDate: any
var intervalTotalGamePrematch: any

const { Header, Content } = Layout;
export interface InfoChooseGame {
    icon: string, lable: string
}

function formatDateNow() {
    let d = new Date().toLocaleDateString()
    return d
}

function formatTimeNow() {
    let d = new Date().toLocaleTimeString()
    return d
}

function formatDate(UTC: string) {
    let d = new Date(UTC).toLocaleDateString()
    return d
}

function formatTime(UTC: string) {
    let d = new Date(UTC).toLocaleTimeString()
    return d
}

function arraysEqual<T>(array1: T[], array2: T[]): boolean {
    // Nếu độ dài của mảng không bằng nhau thì chắc chắn chúng không bằng nhau
    if (array1.length !== array2.length) {
        return false;
    }

    // So sánh từng phần tử của hai mảng
    for (let i = 0; i < array1.length; i++) {
        if (array1[i] !== array2[i]) {
            return false;
        }
    }

    // Nếu tất cả các phần tử đều bằng nhau, trả về true
    return true;
}

interface DashBoardProps {
    showSpan: boolean;
    setShowSpan: React.Dispatch<React.SetStateAction<boolean>>;
    showNLF: boolean;
    setShowNLF: React.Dispatch<React.SetStateAction<boolean>>;
    showMLB: boolean;
    setShowMLB: React.Dispatch<React.SetStateAction<boolean>>;
    isHomePage: boolean;
    setIsHomePage: React.Dispatch<React.SetStateAction<boolean>>;
}
const SoccerMainNew: React.FC<DashBoardProps> = ({ showSpan, setShowSpan, showNLF, setShowNLF, showMLB, setShowMLB, isHomePage, setIsHomePage }) => {

    const dispatch = useDispatch();
    const pushToast = useToast();
    const pushLoading = useLoading();
    const { userId, presentMoney } = useSelector(selectorUser);
    const location = useLocation();

    const [socket, setSocket] = useState<any>(null);

    const [billCode, setBillCode] = useState<any>("");
    const [menu, setMenu] = useState<any>(items)
    const [ipDevice, setIpDevice] = useState<any>("");
    const [locationDevice, setLocationDevice] = useState<any>("");
    const [time, setTime] = useState(0)

    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(async (data) => {
                setIpDevice(String(data.ip))
                await fetch('http://www.geoplugin.net/json.gp?ip=' + String(data.ip)).then(res => res.json()).then(data => {
                    setLocationDevice(data.geoplugin_city ? data.geoplugin_city : "")
                })
            })
            .catch(error =>
                console.log("Can't get IP and Location from device")
            )
    }, [])

    const viewSettings = async () => {
        const ketqua = await homePageApi.viewSettings()
        setMenu([])
        if (ketqua?.success) {
            if (ketqua.data.bettings.Straight === 1) {
                setMenu((data: any) => [...data, {
                    key: '1',
                    label: 'Straight',
                }])
            }
            if (keyGameTabSoccer === "gameOffline") {
                if (ketqua.data.bettings.Parlay === 1) {
                    setMenu((data: any) => [...data, {
                        key: '2',
                        label: 'Parlay',
                    }])
                }
            }
            // if (ketqua.data.bettings.Teaser === 1) {
            //     setMenu((data: any) => [...data, {
            //         key: '3',
            //         label: 'Teaser',
            //         children:
            //             <>
            //                 <div className="flex items-start rounded-md pr-4 pl-4 pt-1 pb-1 mb-[30px] border-[#D4B962] border-solid border-[1px] bg-[#F6F1E0] text-[#D4B962] ">
            //                     <WarningOutlined className="pt-1" />
            //                     <span className="pl-2 text-left">2+ Football or Basketball selections needed.
            //                         for a Teaser bet.
            //                     </span>
            //                 </div>
            //                 <Empty className="pl-2 pr-2" description='Teasers allow you to combine 2+ Football or Basketball selections from the Spread and Total Match Point bet types and allow you to alter the spread, to increase the probability of winning.' />
            //             </>,
            //     }])
            // }
            // if (ketqua.data.bettings.IfBet === 1) {
            //     setMenu((data: any) => [...data, {
            //         key: '4',
            //         label: 'If Bet',
            //         children:
            //             <>
            //                 <div className="flex items-center rounded-md pr-4 pl-4 pt-1 pb-1 mb-[30px] border-[#D4B962] border-solid border-[1px] bg-[#F6F1E0] text-[#D4B962] ">
            //                     <WarningOutlined />
            //                     <span className="pl-2">2+ Bets are needed to create an IF Bet.</span>
            //                 </div>
            //                 <Empty className="pl-2 pr-2 text-center" description='Wager on your chosen 1st selection, if that selecton wins, then an amount up to the original “ to win " amount will be wagered on your next selection. If your 1st selection losses. There is no action afterwards, your bet is a loser.' />
            //             </>,
            //     }])
            // }
            // if (ketqua.data.bettings.Reverse === 1) {
            //     setMenu((data: any) => [...data, {
            //         key: '5',
            //         label: 'Reverse',
            //         children:
            //             <>
            //                 <div className="flex items-center rounded-md pr-3 pl-3 pt-1 pb-1 mb-[30px] border-[#D4B962] border-solid border-[1px] bg-[#F6F1E0] text-[#D4B962] ">
            //                     <WarningOutlined />
            //                     <span className="pl-1">2+ Bets are needed to create an Reverse.</span>
            //                 </div>
            //                 <Empty className="pl-2 pr-2 text-center" description='An" Action Reverse " bet links multiple bets: the next bet is activated regardless of the previous bets outcome, as long as the event occurs.' />
            //             </>,
            //     }])
            // }
        }
    }

    useEffect(() => {
        // gTournament()
        // gListOfGamesForTheHomepage()
        return (() => {
            setListOfGamesForTheSoccer([])
        })
    }, [])
    const [listOfGamesForTheSoccer, setListOfGamesForTheSoccer] = useState<homePageApi.InfoGameSoccer[]>([])
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [typeGame, setTypeGame] = useState<string>('soccer')

    useEffect(() => {
        // gTournamentSoccer()
        // gListOfGamesForTheHomepageSoccer()
        resetData()
        clearInterval(intervalListDate)
        gListDate()
        intervalListDate = setInterval(() => {
            gListDate()
        }, 10000)
        gAllTournamentSoccer()
        gPrematchTournamentSoccer()
        gAllTournamentSoccerLive()
    }, [])

    const resetData = () => {
        setTotalBetSoccer(false)
        setShowTotalStakeSoccer(false)
        setShowModalBetsSoccer(false)
        setShowModalBillSoccer(false)
        setShowModalWarningSoccer(false)
        setShowModalConfirmBetSoccer(false)
        setStatusModalConfirmBetSoccer(1)

        setIsWarningBetParlaySoccer(false)
        // setOpenKeys(["mainMenu"])
        setTotalStakeSoccer(0)
        setTotalAmountStrightSoccer(0)
        setTotalAmountParlaySoccer(0)
        // setTotalGameSoccer(0)

        setTournamentsSoccer(itemMenu[4])


        setListChooseGameSoccer([])
        setListChooseBetSoccer([])
        setListaddStrightBetsSlipSoccer([])
        setListParlayBetsSlipSoccer([])
        setListBetStrightSoccer([])
        setListBetParlaySoccer([])
        setListBillStrightSoccer([])
        setListBillParlaySoccer(undefined)

        setListTournamentSoccerNew([])
        setListTournamentSoccerNewLive([]);
        setListDateFilterSoccer([]);
    }

    const [showTotalBetSoccer, setTotalBetSoccer] = useState(false)
    const [showTotalStakeSoccer, setShowTotalStakeSoccer] = useState(false)
    const [showModalBetsSoccer, setShowModalBetsSoccer] = useState(false)
    const [showModalBillSoccer, setShowModalBillSoccer] = useState(false)
    const [showModalWarningSoccer, setShowModalWarningSoccer] = useState(false)
    const [showModalConfirmBetSoccer, setShowModalConfirmBetSoccer] = useState(false)
    const [statusModalConfirmBetSoccer, setStatusModalConfirmBetSoccer] = useState(1)
    const [updateOdds, setUpdateOdds] = useState(false)
    const [keyBetTabsSoccer, setKeyBetTabsSoccer] = useState("1");
    const [keyGameTabSoccer, setKeyGameTabSoccer] = useState<"gameOnline" | "gameOffline">('gameOnline');
    const [typeListGameSoccer, setTypeListGameSoccer] = useState<"main" | "select">('main');
    const [selectTournament, setSelectTournament] = useState('');

    const [isWarningBetParlaySoccer, setIsWarningBetParlaySoccer] = useState(false);
    const [totalStakeSoccer, setTotalStakeSoccer] = useState<number>(0);
    const [totalAmountStrightSoccer, setTotalAmountStrightSoccer] = useState(0)
    const [totalAmountParlaySoccer, setTotalAmountParlaySoccer] = useState(0)
    const [totalOddsParlaySoccer, setTotalOddsParlaySoccer] = useState(0)
    const [totalGameSoccer, setTotalGameSoccer] = useState(0)
    const [totalGameLiveSoccer, setTotalGameliveSoccer] = useState(0)
    const [totalAllGameSoccer, setTotalAllGameSoccer] = useState(0)

    const [tournamentsSoccer, setTournamentsSoccer] = useState(itemMenu[4])

    const [listChooseGameSoccer, setListChooseGameSoccer] = useState<string[]>([])
    const [listChooseBetSoccer, setListChooseBetSoccer] = useState<bettingApi.ChooseBetSoccer[]>([])
    const [listAddStrightBetsSlipSoccer, setListaddStrightBetsSlipSoccer] = useState<bettingApi.InfoAddStrightSoccerTmp[]>([])
    const [listParlayBetsSlipSoccer, setListParlayBetsSlipSoccer] = useState<bettingApi.InfoParlaySoccerTmp[]>([])

    const [listBetStrightSoccer, setListBetStrightSoccer] = useState<bettingApi.InfoBetStrightSoccer[]>([])
    const [listBetParlaySoccer, setListBetParlaySoccer] = useState<bettingApi.InfoParlaySoccer[]>([])

    const [listBillStrightSoccer, setListBillStrightSoccer] = useState<bettingApi.InfoBillStrightSoccer[]>([])
    const [listBillParlaySoccer, setListBillParlaySoccer] = useState<bettingApi.InfoBillParlaySoccer>()
    const [listTournamentSoccerNew, setListTournamentSoccerNew] = useState<homePageApi.InfoTournamentSoccer[]>([])
    const [listTournamentSoccerNewLive, setListTournamentSoccerNewLive] = useState<homePageApi.InfoTournamentSoccer[]>([])
    const [listTournamentSoccerNewSideBar, setListTournamentSoccerNewSideBar] = useState<homePageApi.InfoTournamentSoccer[]>([])

    const [listDateFilterSoccer, setListDateFilterSoccer] = useState<homePage_V2Api.infoListDate[]>([])
    const [dateFilterSoccer, setDateFilterSoccer] = useState<string>('')
    const [resetBetGame, setResetBetGame] = useState<boolean>(false)
    const [settingBet, setSettingBet] = useState<bettingApi.InfoSettingsBet>()


    useEffect(() => {
        viewSettings()
    }, [keyGameTabSoccer])

    const handleCheckQuantityMoneyStraight = async (keytab: number, total: number) => {
        pushLoading(true);
        var ketQua
        if (keytab === 1) {
            ketQua = await homePageApi.checkQuantityMoneyStraight(total);
        } else if (keytab === 2) {
            ketQua = await homePageApi.checkQuantityMoneyParlay(total);
        } else {
            ketQua = await homePageApi.checkQuantityMoneyTeaser(total);
        }
        if (ketQua?.success) {
            // aStoreStrightBetsSlip()
            pushLoading(false);
            return true
        } else {
            pushToast(ketQua?.message, "warn");
            pushLoading(false);
            return false
        }
    }

    const showHideTotalStakeSoccer = () => {
        setShowTotalStakeSoccer(!showTotalStakeSoccer)
    }

    //Soccer
    const resetTotalGameSoccer = async () => {
        totalGamePrematchSoccer.current = 0
        totalGameLivePrematchSoccer.current = 0
        setTotalGameSoccer(0)
        setTotalGameliveSoccer(0)
    }

    const checkFirstTimeDateFilter = useRef(0)
    const gListDate = async () => {
        if (window.location.pathname === "/Soccer_V1") {
            var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
            const ketQua = await homePage_V2Api.listDate(timeZone, selectTournament ? "" : "Soccer", selectTournament);
            if (ketQua?.success) {
                setListDateFilterSoccer(ketQua.data)
                if (checkFirstTimeDateFilter.current === 0) {
                    setDateFilterSoccer("all")
                    // setDateFilterSoccer(ketQua.data[0].value ? ketQua.data[0].value : "Today")
                    checkFirstTimeDateFilter.current = 1
                }
            } else {
                setListDateFilterSoccer([]);
                pushToast(ketQua?.message, "warn");
            }
        }
    }

    const changeDateFilter = async (date: string) => {
        setDateFilterSoccer(date)
        handleRadioChangeSoccer('gameOffline')
        if (date === "all") {
            gAllTournamentSoccer("allgame")
        }
        // setListChooseBetSoccer([])
        // setListaddStrightBetsSlipSoccer([])
        // setListParlayBetsSlipSoccer([])
    }

    const resetBet = useThrottle(async () => {
        var timeTotal = 10
        const interval = setInterval(() => {
            if (timeTotal > 0) {
                timeTotal -= 1
                setTime(timeTotal)
            } else {
                clearInterval(interval)
            }
        }, 1000);

        pushLoading(true)
        // clearInterval(interval);
        setResetBetGame(true)
        if (typeListGameSoccer === 'main') {
            gPrematchTournamentSoccer()
        } else {
            await gPrematchTournamentSoccer()
            gListOfGameByCountryAndGameSoccer()
        }
        totalGamePrematchSoccer.current = 0
        setTotalGameSoccer(0)
        // interval = setInterval(() => {
        //     gAllGameSoccerOffline()
        // }, 60000);
        pushLoading(false)
    }, 10000)

    const updateBetGameReset = async () => {
        setResetBetGame(false)
    }

    const gGameSoccerByTournamentName = async (tournament: string) => {
        if (typeListGameSoccer === 'select') {
            const ketQua = await homePage_V2Api.getGameSoccerByTournamentName(tournament, dateFilterSoccer);
            if (ketQua?.success) {
                setTotalGameSoccer(ketQua.data.length)
            } else {
                pushToast(ketQua?.message, "warn");
            }
        }
    }
    const gPrematchTournamentSoccerChangeDate = async () => {
        await gPrematchTournamentSoccer()
        gListOfGameByCountryAndGameSoccer()
    }

    useEffect(() => {
        if (dateFilterSoccer !== '' && dateFilterSoccer !== 'all') {
            if (typeListGameSoccer === 'main') {
                gPrematchTournamentSoccer()
            } else {
                gPrematchTournamentSoccerChangeDate()
            }
        }
        totalGamePrematchSoccer.current = 0
        setTotalGameSoccer(0)
    }, [dateFilterSoccer])

    const gAllTournamentSoccer = useThrottle(async (value?: string, reload?: boolean) => {
        if (window.location.pathname === "/Soccer_V1") {
            if (!reload) {
                clearInterval(intervalPrematchTournament)
            }
            const ketQua = await homePage_V2Api.allTournamentSoccerSbobet("All");
            if (ketQua?.success) {
                if (selectTournament && selectTournament !== "") {
                    setTotalAllGameSoccer(ketQua.data.filter(item => item.tournament === selectTournament).length ? Number(ketQua.data.filter(item => item.tournament === selectTournament)[0].totalMatch) : 0)
                    setListTournamentSoccerNewSideBar(ketQua.data)
                    if (value) {
                        setListTournamentSoccerNew(ketQua.data.filter(item => item.tournament === selectTournament))
                    }
                } else {
                    setTotalAllGameSoccer(ketQua?.totalMatch ? ketQua?.totalMatch : 0)
                    setListTournamentSoccerNewSideBar(ketQua.data)
                    if (value) {
                        setListTournamentSoccerNew(ketQua.data)
                    }
                }
                clearInterval(intervalTotalGamePrematch)
                intervalTotalGamePrematch = setInterval(() => {
                    gAllTournamentSoccer(undefined, true)
                }, 20000)
            } else {
                setListTournamentSoccerNewSideBar([]);
                pushToast(ketQua?.message, "warn");
            }
        }
        // intervalPrematchTournament = setInterval(() => {
        //     clearAllStrightBetsSlipSoccer()
        //     // gAllTournamentSoccer()
        // }, 3600000)
    }, 500)


    const gPrematchTournamentSoccer = useThrottle(async () => {
        clearInterval(intervalPrematch)
        pushLoading(true);
        var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const ketQua = await homePage_V2Api.prematchTournamentSoccerSbobet(dateFilterSoccer, timeZone);
        if (ketQua?.success) {
            setTotalGameSoccer(ketQua?.count ?? 0)
            setListTournamentSoccerNew(ketQua.data)
        } else {
            setListTournamentSoccerNew([]);
            pushToast(ketQua?.message, "warn");
        }
        pushLoading(false);
        intervalPrematch = setInterval(() => {
            gPrematchTournamentSoccer()
        }, 3600000)
    }, 500)

    const gAllTournamentSoccerLive = useThrottle(async () => {
        pushLoading(true);
        const ketQua = await homePage_V2Api.liveTournamentSoccerSbobet();
        if (ketQua?.success) {
            setListTournamentSoccerNewLive(ketQua.data)
        } else {
            setListTournamentSoccerNewLive([]);
            pushToast(ketQua?.message, "warn");
        }
        pushLoading(false);
    }, 500)

    const cMoneyBettingSoccer = useThrottle(async () => {
        setShowModalBetsSoccer(false)
        pushLoading(true);
        var checkQuantityMoneyStraight
        if (keyBetTabsSoccer === "1") {
            checkQuantityMoneyStraight = await handleCheckQuantityMoneyStraight(+keyBetTabsSoccer, totalAmountStrightSoccer)
        }
        if (keyBetTabsSoccer === "2") {
            checkQuantityMoneyStraight = await handleCheckQuantityMoneyStraight(+keyBetTabsSoccer, totalAmountParlaySoccer)
        }
        if (!checkQuantityMoneyStraight) {
            pushLoading(false);
            return
        }
        if (keyBetTabsSoccer === "1") {
            const ketQua = await bettingApi.checkMoneyBetting(presentMoney, totalAmountStrightSoccer, listBetStrightSoccer, keyBetTabsSoccer === "1" ? "Straight" : "Parlay");
            if (ketQua?.success) {
                aStoreStrightBetsSlipSoccer()
            } else {
                pushToast(ketQua?.message, "warn");
                if (ketQua.type === "nem") {
                    setShowModalWarningSoccer(true)
                }
            }
        }
        if (keyBetTabsSoccer === "2") {
            await handleDoubleCheckBetParlaySoccer()
        }

        pushLoading(false);
    }, 1000)

    const aStoreStrightBetsSlipSoccer = useThrottle(async () => {
        if (keyGameTabSoccer === 'gameOnline') {
            setStatusModalConfirmBetSoccer(1)
            setShowModalConfirmBetSoccer(true)
        } else {
            pushLoading(true)
        }
        if (listBetStrightSoccer[0].live && listBetStrightSoccer[0].live === true) {
            listBetStrightSoccer[0].stage = "1"
        } else {
            listBetStrightSoccer[0].stage = "0"
        }
        listBetStrightSoccer[0].win = Math.round(Number(listBetStrightSoccer[0].win))
        const ketQua = await bettingApi.addStoreStraightSoccerBetsSlip(userId, listBetStrightSoccer, ipDevice, locationDevice);
        if (ketQua?.success) {
            setShowModalConfirmBetSoccer(false)
            dispatch(setUserMoney(ketQua.presentMoney || "0"))
            if (listBetStrightSoccer[0].live === true) {
                ketQua.data[0].live = true
                setListBillStrightSoccer(ketQua.data)
            } else {
                ketQua.data[0].stage = "0"
                setListBillStrightSoccer(ketQua.data)
            }
            setBillCode(ketQua.billCode)
            clearAllStrightBetsSlipSoccer()
            setShowModalBillSoccer(true)
        } else {
            if (keyGameTabSoccer === 'gameOnline') {
                setStatusModalConfirmBetSoccer(ketQua.status ? ketQua.status : 1)
                setTimeout(() => {
                    setShowModalConfirmBetSoccer(false)
                }, 3000)
            } else {
                pushToast(ketQua?.message, "warn");
            }
        }
        pushLoading(false);
    }, 1000)

    const aStoreParlayBetsSlipSoccer = useThrottle(async () => {
        pushLoading(true);
        listBetParlaySoccer.forEach((element, index) => {
            listBetParlaySoccer[index].stage = "0"
        });
        const ketQua = await bettingApi.addStoreParlaySoccer(userId, totalOddsParlaySoccer, totalAmountParlaySoccer, listBetParlaySoccer, ipDevice, locationDevice);
        if (ketQua?.success) {
            dispatch(setUserMoney(ketQua.presentMoney || "0"))
            setListBillParlaySoccer(ketQua.data)
            setBillCode(ketQua.billCode)
            clearAllStrightBetsSlipSoccer()
            setShowModalBillSoccer(true)
        } else {
            pushToast(ketQua?.message, "warn");
        }
        pushLoading(false);
    }, 1000)

    const handelChooseTournamentsSoccer = (value: itemMenu) => {
        if (value.id !== 9) {
            setTournamentsSoccer(value)
            // gListGameByTournamentSoccer(value.id)
        }
        if (value.id === 0) {
            // gListOfGamesForTheHomepageSoccer()
        }
        window.location.href = value.href
        // navigator(value.href)
    }

    const checkAddStrightBetsSlipSoccer = useThrottle(async (
        country: string, order: number, id: number, tournament: string, game: string, teamChoose: string, scoreChoose: string, lineBetting: bettingApi.lineBettingSoccer, dateEvent: string, option?: any, live?: boolean, idGame?: number | string, homeLiveScore?: number, awayLiveScore?: number
    ) => {
        pushLoading(true)
        setUpdateOdds(false)
        if (keyGameTabSoccer === "gameOnline") {
            await setListChooseBetSoccer([])
            await setListaddStrightBetsSlipSoccer([])
        }

        const isDuplicate = listChooseBetSoccer.some(
            (bet) => bet.teamChoose === teamChoose && bet.lineBetting === lineBetting && bet?.order === order
        );
        var updatedList: any

        if (keyGameTabSoccer === "gameOnline") {
            updatedList = isDuplicate
                ? listChooseBetSoccer.filter((bet) => !(bet.teamChoose === teamChoose && bet.lineBetting === lineBetting && bet?.order === order))
                : [{ country, order, id, tournament, game, teamChoose, scoreChoose, lineBetting, dateEvent, option, live, idGame }];
        } else {
            updatedList = isDuplicate
                ? listChooseBetSoccer.filter((bet) => !(bet.teamChoose === teamChoose && bet.lineBetting === lineBetting && bet?.order === order))
                : [...listChooseBetSoccer, { country, order, id, tournament, game, teamChoose, scoreChoose, lineBetting, dateEvent, option, live, idGame }];
        }

        var checkMax = Math.max(...[Number(settingBet?.quantity.straight) ? Number(settingBet?.quantity.straight) : 0, Number(settingBet?.quantity.parlay) ? Number(settingBet?.quantity.parlay) : 0, Number(settingBet?.quantity.teaser) ? Number(settingBet?.quantity.teaser) : 0, 1])
        if (updatedList.length > checkMax) {
            pushToast(`Your bet amount exceeds the limit ${checkMax}`, "warn");
        } else {
            setListChooseBetSoccer(updatedList);
        }
        if (!isDuplicate) {
            aStrightBetsSlipSoccer(country, order, id, tournament, game, teamChoose, scoreChoose, lineBetting, dateEvent, option, live, idGame, homeLiveScore, awayLiveScore)
        }
        else
            rStrightBetsSlipSoccer(country, order, id, tournament, game, teamChoose, scoreChoose, lineBetting, dateEvent, option, live)

        setTimeout(() => {
            pushLoading(false)
        }, 2000)
    }, 400)

    const settingsBet = async () => {
        const ketQua1 = await bettingApi.getSettingsBet()
        if (ketQua1?.success) {
            setSettingBet(ketQua1.data)
        }
    }

    const aStrightBetsSlipSoccer = async (
        country: string, order: number, id: number, tournament: string, game: string, teamChoose: string, scoreChoose: string, lineBetting: bettingApi.lineBettingSoccer, dateEvent: string, option?: any, live?: boolean, idGame?: number | string, homeLiveScore?: number, awayLiveScore?: number
    ) => {
        pushLoading(true);
        if (settingBet?.quantity.straight && settingBet?.quantity.straight > listAddStrightBetsSlipSoccer.length) {
            const ketQua = await bettingApi.addStraightBetSoccerBetsSlip(
                country, tournament, game, teamChoose, scoreChoose, lineBetting, dateEvent
            )
            if (ketQua?.success) {
                if (keyGameTabSoccer === "gameOnline") {
                    setListaddStrightBetsSlipSoccer((prevList) => [
                        { ...ketQua.data, isCheck: true, amount: 0, win: 0, id, option, live, idGame, homeLiveScore, awayLiveScore }
                    ]);
                } else {
                    setListaddStrightBetsSlipSoccer((prevList) => [
                        ...prevList,
                        { ...ketQua.data, isCheck: true, amount: 0, win: 0, id, option, live, idGame, homeLiveScore, awayLiveScore }
                    ]);
                }

                setTotalStakeSoccer(0)
            } else {
                pushToast(ketQua?.message, "warn");
            }
        } else {
            if (keyBetTabsSoccer === "1") {
                pushToast(`Your straight bet amount exceeds the limit ${settingBet?.quantity?.straight ? settingBet?.quantity?.straight.toString() : ""}`, "warn");
            }
        }
        pushLoading(false);
    }

    const rStrightBetsSlipSoccer = async (
        country: string, order: number, id: number, tournament: string, game: string, teamChoose: string, scoreChoose: string, lineBetting: bettingApi.lineBettingSoccer, dateEvent: string, option?: any, live?: boolean
    ) => {
        const updatedList = listAddStrightBetsSlipSoccer.filter((bet) => !(bet.teamChoose === teamChoose && bet.lineBetting === lineBetting))
        var check = 0
        await updatedList && updatedList.map(item => {
            if (item.change) {
                check++
            }
        })
        if (check === 0) {
            setHiddenBetPlaceSoccer(false)
        } else {
            setHiddenBetPlaceSoccer(true)
        }
        setListaddStrightBetsSlipSoccer(updatedList)
        rParlayBetsSlipSoccer(game, teamChoose, lineBetting)
    }


    const rParlayBetsSlipSoccer = async (game: string, teamChoose?: string, lineBetting?: bettingApi.lineBettingSoccer) => {
        var updatedList
        if (teamChoose && lineBetting) {
            updatedList = listParlayBetsSlipSoccer.filter((bet) => !(bet.teamChoose === teamChoose && bet.lineBetting === lineBetting))
        } else {
            updatedList = listParlayBetsSlipSoccer.filter((bet) => (bet.game === game))
        }
        var check = 0
        await updatedList && updatedList.map(item => {
            if (item.change) {
                check++
            }
        })
        if (check === 0) {
            setHiddenBetPlaceSoccer(false)
        } else {
            setHiddenBetPlaceSoccer(true)
        }
        if (settingBet?.quantity.parlay && settingBet?.quantity.parlay > listParlayBetsSlipSoccer.length) {
            const updatedListParlay = listParlayBetsSlipSoccer.filter((bet) => (bet.game !== game || bet.teamChoose !== teamChoose || bet.lineBetting !== lineBetting))
            await setListParlayBetsSlipSoccer(updatedListParlay)
            if (updatedListParlay.length) {
                aParlayBetsSlipSoccer()
            }
        } else {
            const updatedListParlay = listParlayBetsSlipSoccer.filter((bet) => (bet.game !== game || bet.teamChoose !== teamChoose || bet.lineBetting !== lineBetting))
            setListParlayBetsSlipSoccer(updatedListParlay)
        }
    }

    const checkConditionsBetParlaySoccer = useDebounce((arr: bettingApi.InfoParlayBetsSlipTMP[]): Boolean => {
        if (arr.length < 2) {
            setIsWarningBetParlaySoccer(true)
            return false; // Trường hợp 1
        }
        // Kiểm tra xem có bất kỳ cặp game nào giống nhau hay không
        const gameSet: Set<string> = new Set(); // Sử dụng Set để kiểm tra các game duy nhất
        for (const item of arr) {
            if (gameSet.has(item.game)) {
                checkWarningParlaySoccer(item.game, arr)
                setIsWarningBetParlaySoccer(true)
                return false; // Trường hợp 1
            } else {
                gameSet.add(item.game);
            }
        }
        checkWarningParlaySoccer("", arr)
        setIsWarningBetParlaySoccer(false)
        // Nếu không có cặp game nào giống nhau và mảng có 2 hoặc nhiều hơn 2 phần tử
        return true; // Trường hợp 2
    }, 1000)

    const checkWarningParlaySoccer = useThrottle((game: string, arr: bettingApi.InfoParlaySoccerTmp[]) => {
        if (game) {
            listParlayBetsSlipSoccer.map((item) => {
                if (item.game === game) {
                    item.isWarning = true
                }
            })
        } else {
            // const updatedList = listParlayBetsSlipSoccer.map(item => ({ ...item, isWarning: false }));
            // setListParlayBetsSlipSoccer(updatedList);
        }

    }, 500)

    const aParlayBetsSlipSoccer = useThrottle(async (
    ) => {
        pushLoading(true);
        if (settingBet?.quantity.parlay && (settingBet?.quantity.parlay > listParlayBetsSlipSoccer.length || (settingBet?.quantity.parlay === listParlayBetsSlipSoccer.length && settingBet?.quantity.parlay - 1 === listChooseBetSoccer.length))) {
            const ketQua = await bettingApi.addParlayBetSoccer(listChooseBetSoccer)
            if (ketQua?.success) {
                setListParlayBetsSlipSoccer(ketQua.data.data.map(bet => ({ ...bet, isCheck: true, isWarning: false })));
                // setTotalAmountParlaySoccer(100)
                setTotalOddsParlaySoccer(ketQua.data.totalOdds)

            } else {
                pushToast(ketQua?.message, "warn");
            }
        } else {
            if (keyBetTabsSoccer === "2") {
                pushToast(`Your parlay bet amount exceeds the limit ${settingBet?.quantity?.parlay ? settingBet?.quantity?.parlay.toString() : ""}`, "warn");
            }
        }
        setTimeout(() => {
            pushLoading(false);
        }, 2000)
    }, 500)


    const clearAllStrightBetsSlipSoccer = () => {
        setListChooseBetSoccer([])
        setListaddStrightBetsSlipSoccer([])
        setListParlayBetsSlipSoccer([])
        setListBetStrightSoccer([])
        setListBetParlaySoccer([])
        setTotalStakeSoccer(0)
        setTotalOddsParlaySoccer(0)
        setTotalAmountParlaySoccer(0)
        setHiddenBetPlaceSoccer(false)
    };

    const rStrightBetsSlipByIndexSoccer = (value: number) => {
        if (listChooseBetSoccer.length) {
            var updateListChooseBet = listChooseBetSoccer.filter((item, index) => (index !== value))
            setListChooseBetSoccer(updateListChooseBet);
        }
        if (keyBetTabsSoccer === '1') {
            if (listAddStrightBetsSlipSoccer.length) {
                var updateListStraightBet = listAddStrightBetsSlipSoccer.filter((item, index) => (index !== value))
                setListaddStrightBetsSlipSoccer(updateListStraightBet);
            }
            if (listParlayBetsSlipSoccer.length) {
                var updateListParlay = listParlayBetsSlipSoccer.filter((item, index) => (index !== value))
                setListParlayBetsSlipSoccer(updateListParlay);
            }
        } else {
            if (listAddStrightBetsSlipSoccer.length) {
                var updateListStraightBet = listAddStrightBetsSlipSoccer.filter((item, index) => (index !== value))
                setListaddStrightBetsSlipSoccer(updateListStraightBet);
            }
            if (listParlayBetsSlipSoccer.length) {
                if (listParlayBetsSlipSoccer.length === 1) {
                    setListaddStrightBetsSlipSoccer([])
                }
                var updateListParlay = listParlayBetsSlipSoccer.filter((item, index) => (index !== value))
                setListParlayBetsSlipSoccer(updateListParlay);
            }
        }
    }

    const handleAllClickSoccer = (e: number) => {
        switch (keyBetTabsSoccer) {
            case "1":
                if (totalStakeSoccer !== 0) {
                    if (totalStakeSoccer < 10000) {
                        setTotalStakeSoccer(totalStakeSoccer + e)
                        uAllAmountAddStrightBetsSlipSoccer(totalStakeSoccer + e)
                    } else {
                        pushToast("The total bet cannot exceed $10,000", "warn");
                    }
                } else {
                    setTotalStakeSoccer(e)
                    uAllAmountAddStrightBetsSlipSoccer(e)
                }
                break;
            case "2":
                if (totalAmountParlaySoccer) {
                    if (totalStakeSoccer < 10000) {
                        setTotalAmountParlaySoccer(totalAmountParlaySoccer + e)
                    } else {
                        pushToast("The total bet cannot exceed $10,000", "warn");
                    }
                } else {
                    setTotalAmountParlaySoccer(e)
                }
                break;
            default:
                break;
        }
    }

    const handleAllNumberClickSoccer = (e: number) => {
        switch (keyBetTabsSoccer) {
            case "1":
                if (totalStakeSoccer) {
                    if (totalStakeSoccer < 10000) {
                        setTotalStakeSoccer(Number(`${totalStakeSoccer}${e}`))
                        uAllAmountAddStrightBetsSlipSoccer(Number(`${totalStakeSoccer}${e}`))
                    } else {
                        pushToast("The total bet cannot exceed $10,000", "warn");
                    }
                } else {
                    setTotalStakeSoccer(e)
                    uAllAmountAddStrightBetsSlipSoccer(e)
                }
                break;
            case "2":
                if (totalAmountParlaySoccer) {
                    if (totalAmountParlaySoccer < 10000) {
                        setTotalAmountParlaySoccer(Number(`${totalAmountParlaySoccer}${e}`))
                    } else {
                        pushToast("The total bet cannot exceed $10,000", "warn");
                    }
                } else {
                    setTotalAmountParlaySoccer(e)
                }
                break;
            default:
                break;
        }
    }

    const handleAllDeleteClickSoccer = (e: number) => {
        switch (keyBetTabsSoccer) {
            case "1":
                setTotalStakeSoccer(0)
                uAllAmountAddStrightBetsSlipSoccer(0)
                break;
            case "2":
                setTotalAmountParlaySoccer(0)
                break;
            default:
                break;
        }
        // setTotalStakeSoccer(0)
        // uAllAmountAddStrightBetsSlipSoccer(0)
    }

    const uAllAmountAddStrightBetsSlipSoccer = (value: number) => {
        setListaddStrightBetsSlipSoccer((prevList) =>
            prevList.map((item) => ({ ...item, amount: value, win: getPiceWinStright(value, item.scoreChoose) }))
        );
    };

    const handleCheckBetStrightSoccer = useThrottle(() => {
        const filteredArray = listAddStrightBetsSlipSoccer.filter((item) => (item.isCheck && item.amount !== 0));
        if (filteredArray.length === 0) {
            pushToast("Please select the bet and enter the bet amount!", "warn");
            return
        }

        const updatedArray = filteredArray.map(({ isCheck, amount, ...rest }) => ({
            ...rest,
            amount: amount,
        }));
        setListBetStrightSoccer(updatedArray)
        setShowModalBetsSoccer(true)
    }, 1000)


    const updateBetStrightSoccer = useThrottle((listStrightBetsSlip: bettingApi.InfoAddStrightSoccerTmp[]) => {
        const filteredArray = listStrightBetsSlip.filter((item) => (item.isCheck && item.amount !== 0));
        const updatedArray = filteredArray.map(({ isCheck, amount, ...rest }) => ({
            ...rest,
            amount: amount,
            win: getPiceWinStright(amount, rest.scoreChoose)
        }));
        setListBetStrightSoccer(updatedArray)
    }, 100)

    const handleCheckBetParlaySoccer = useThrottle(() => {
        const filteredArray = listParlayBetsSlipSoccer.filter((item) => (item.isCheck));
        if (isWarningBetParlaySoccer) {
            pushToast("Parlay cannot be placed as some of your legs cannot be combined", "warn");
            return
        }
        if (filteredArray.length <= 1) {
            pushToast("Please choose 2 or more bets!", "warn");
            return
        }
        if (totalAmountParlaySoccer === 0) {
            pushToast("Please select the bet amount!", "warn");
            return
        }

        const updatedArray = filteredArray.map(({ isCheck, ...rest }) => ({
            ...rest
        }));
        setListBetParlaySoccer(updatedArray)
        setShowModalBetsSoccer(true)
    }, 1000)

    const handleDoubleCheckBetParlaySoccer = useThrottle(async () => {
        const filteredArray = listParlayBetsSlipSoccer.filter((item) => (item.isCheck));
        if (isWarningBetParlaySoccer) {
            pushToast("Parlay cannot be placed as some of your legs cannot be combined", "warn");
            return
        }
        if (filteredArray.length <= 1) {
            pushToast("Please choose 2 or more bets!", "warn");
            return
        }
        if (totalAmountParlaySoccer === 0) {
            pushToast("Please select the bet amount!", "warn");
            return
        }

        const updatedArray = filteredArray.map(({ isCheck, ...rest }) => ({
            ...rest
        }));
        setListBetParlaySoccer(updatedArray)
        const ketQua = await bettingApi.checkMoneyBetting(presentMoney, totalAmountParlaySoccer, updatedArray, keyBetTabsSoccer === "1" ? "Straight" : "Parlay");
        if (ketQua?.success) {
            aStoreParlayBetsSlipSoccer()
        } else {
            pushToast(ketQua?.message, "warn");
            if (ketQua.type === "nem") {
                setShowModalWarningSoccer(true)
            }
        }
    }, 1000)

    const [activeTabKeySoccer, setActiveTabKeySoccer] = useState("1");

    const handleTabsChangeSoccer = (key: string) => {
        setActiveTabKeySoccer(key);
    };

    const handleCloseModalBillSoccer = () => {
        setShowModalBillSoccer(false)
        setListBillStrightSoccer([])
        setListBillParlaySoccer(undefined)

        setListChooseGameSoccer([])
        setListChooseBetSoccer([])
        setListaddStrightBetsSlipSoccer([])
        setListParlayBetsSlipSoccer([])
        setListBetStrightSoccer([])
        setListBetParlaySoccer([])
        setListBillStrightSoccer([])
    };

    const totalGamePrematchSoccer = useRef<number>(0);
    const totalGameLivePrematchSoccer = useRef<number>(0);

    const countTotalSoccer = async (count: number) => {
        // if (listChooseGameSoccer.length > 0 && typeListGameSoccer === 'select') {
        //     await (totalGamePrematchSoccer.current = Number(totalGamePrematchSoccer.current) + count)
        //     setTotalGameSoccer(totalGamePrematchSoccer.current)
        // }
    }

    const countTotalSoccerLive = async (count: number, tournament?: string) => {
        // console.log(tournament, count)
        // const updatedList = listTournamentSoccerNewLive?.map(item => {
        //     if (item.tournament === tournament && count === 0) {
        //         return { ...item, hidden: true };
        //     }
        //     return item;
        // });
        // setListTournamentSoccerNewLive(updatedList)

        // await (totalGameLivePrematchSoccer.current = Number(totalGameLivePrematchSoccer.current) + count)
        // setTotalGameliveSoccer(totalGameLivePrematchSoccer.current)
    }

    useEffect(() => {
        if (keyBetTabsSoccer === '1') {
            if (listAddStrightBetsSlipSoccer.length) {
                setTotalBetSoccer(false)
            } else {
                setTotalBetSoccer(true)
            }
        }
        const totalAmountTmp = listAddStrightBetsSlipSoccer.reduce((accumulator, currentItem) => {
            return currentItem.isCheck ? accumulator + currentItem.amount : accumulator;
        }, 0);
        setTotalAmountStrightSoccer(totalAmountTmp)
    }, [listAddStrightBetsSlipSoccer, keyBetTabsSoccer])

    useEffect(() => {
        if (keyBetTabsSoccer === '2') {
            if (listParlayBetsSlipSoccer.length > 1) {
                setTotalBetSoccer(false)
            } else {
                setTotalBetSoccer(true)
            }
        }
    }, [listParlayBetsSlipSoccer, keyBetTabsSoccer])

    useEffect(() => {
        pushLoading(true)
        settingsBet()
        if (!showModalBillSoccer) {
            switch (true) {
                case listChooseBetSoccer.length > 0:
                    if (keyGameTabSoccer === "gameOffline") {
                        if (settingBet?.quantity.parlay && (settingBet?.quantity.parlay > listParlayBetsSlipSoccer.length || (settingBet?.quantity.parlay === listParlayBetsSlipSoccer.length && settingBet?.quantity.parlay - 1 === listChooseBetSoccer.length))) {
                            aParlayBetsSlipSoccer()
                        }
                        if (listChooseBetSoccer.length > 1 && keyBetTabsSoccer !== "2" && !updateOdds)
                            setKeyBetTabsSoccer("2")
                    }
                    break;
                case listChooseBetSoccer.length === 1:
                    rParlayBetsSlipSoccer(listChooseBetSoccer[0].game)
                    setTotalOddsParlaySoccer(0)
                    setTotalAmountParlaySoccer(0)
                    if (!updateOdds)
                        setKeyBetTabsSoccer("1")
                    break;

                case listChooseBetSoccer.length === 0:
                    setListaddStrightBetsSlipSoccer([])
                    setListParlayBetsSlipSoccer([])
                    break;
                default:
                    setListaddStrightBetsSlipSoccer([])
                    setListParlayBetsSlipSoccer([])
                    setTotalOddsParlaySoccer(0)
                    setTotalAmountParlaySoccer(0)
                    if (!updateOdds)
                        setKeyBetTabsSoccer("1")
                    break;
            }
        }
        pushLoading(false)
    }, [listChooseBetSoccer])

    const gListOfGameByCountryAndGameSoccer = useThrottle(async () => {
        const [key, tournament] = listChooseGameSoccer
        pushLoading(true);
        await resetTotalGameSoccer()
        gGameSoccerByTournamentName(tournament)
        setListTournamentSoccerNew([{ key: +key, tournament: tournament }])
        setListTournamentSoccerNewLive([{ key: +key, tournament: tournament }])
        pushLoading(false);
    }, 500)

    useEffect(() => {
        if (dateFilterSoccer !== "all") {
            if (listChooseGameSoccer[1])
                gListOfGameByCountryAndGameSoccer()
            else {
                gPrematchTournamentSoccer()
                gAllTournamentSoccerLive()
            }
        }
    }, [listChooseGameSoccer])

    useEffect(() => {
        if (listParlayBetsSlipSoccer?.length && keyBetTabsSoccer === '2' && listChooseBetSoccer.length >= 2) {
            const arr = listParlayBetsSlipSoccer.filter(item => item.isCheck === true);
            checkConditionsBetParlaySoccer(arr)
        }
    }, [listParlayBetsSlipSoccer])

    useEffect(() => {
        if (location.pathname === "/") {
            setActiveTabKeySoccer("1");
        } else {

        }
    }, [location]);

    const handleMenuSelectSoccer_V2 = (tournament: string, key: string) => {
        if (listChooseGameSoccer && listChooseGameSoccer[0] === key) {
            setTypeListGameSoccer('main')
            setListChooseGameSoccer([])
            setSelectTournament("");
        } else {
            setTypeListGameSoccer('select')
            setListChooseGameSoccer([key, tournament])
            setSelectTournament(tournament);
        }

    }

    useEffect(() => {
        clearInterval(intervalListDate)
        gListDate()
        intervalListDate = setInterval(() => {
            gListDate()
        }, 20000)
        if (dateFilterSoccer === "all") {
            gAllTournamentSoccer("allgame")
        } else {
            gAllTournamentSoccer()
        }
    }, [selectTournament])

    const onChangeSoccer = (key: string) => {
        setKeyBetTabsSoccer(key)
    };

    const handleRadioChangeSoccer = (value: "gameOnline" | "gameOffline") => {
        setKeyGameTabSoccer(value);
        // clearAllStrightBetsSlipSoccer()
        if (value === 'gameOnline') {
            setMenu([{
                key: '1',
                label: 'Straight',
            }])
        } else {
            setMenu((data: any) => [data, {
                key: '1',
                label: 'Straight',
            }])
            setMenu((data: any) => [...data, {
                key: '2',
                label: 'Parlay',
            }])
            // viewSettings()
        }
    };

    useMemo(() => {
        const totalInfoGameCR = listOfGamesForTheSoccer.length
        // setTotalGame(totalInfoGameCR)
    }, [listOfGamesForTheSoccer])

    const [hiddenBetPlaceSoccer, setHiddenBetPlaceSoccer] = useState<boolean>(false)
    const checkUpdateOddsSoccer = async (
        id: number, point: any, prices: any[]
    ) => {
        var oddsUpdate: any
        var pointUpdate: number | string
        var listNew: any[] = []
        var listNewParlay: any[] = []
        var checkChange = 0
        await listAddStrightBetsSlipSoccer.forEach(element => {
            if (element.id === id) {
                prices.forEach(elementPrice => {
                    if (elementPrice.option === element.option) {
                        checkChange++
                        oddsUpdate = elementPrice.price
                        if (element.option === "X") {
                            pointUpdate = "Draw"
                        } else {
                            pointUpdate = point
                        }
                        if (element.lineBetting === "Fulltime HDP" || element.lineBetting === "First Half HDP") {
                            if (element.option === "h") {
                                pointUpdate = -point
                            } else {
                                pointUpdate = point
                            }
                        }
                    }
                });
                listNew.push({
                    amount: element.amount,
                    dateEvent: element.dateEvent,
                    game: element.game,
                    id: element.id,
                    isCheck: element.isCheck,
                    lineBetting: element.lineBetting,
                    option: element.option,
                    potentialPayout: element.potentialPayout,
                    scoreChoose: pointUpdate + " " + oddsUpdate,
                    // stage: element.stage,
                    teamChoose: element.teamChoose,
                    totalStake: element.totalStake,
                    tournament: element.tournament,
                    // win: element.win,
                    win: getPiceWin(element.amount, element.scoreChoose),
                    change: (pointUpdate + " " + oddsUpdate) !== element.scoreChoose ? true : false,
                })
            } else {
                listNew.push(element)
            }
        });

        await listParlayBetsSlipSoccer.forEach(element => {
            if (element.id === id) {
                prices.forEach(elementPrice => {
                    if (elementPrice.option === element.option) {
                        checkChange++
                        oddsUpdate = elementPrice.price
                        if (element.option === "X") {
                            pointUpdate = "Draw"
                        } else {
                            pointUpdate = point
                        }
                        if (element.lineBetting === "Fulltime HDP" || element.lineBetting === "First Half HDP") {
                            if (element.option === "h") {
                                pointUpdate = -point
                            } else {
                                pointUpdate = point
                            }
                        }
                    }
                });
                listNewParlay.push({
                    country: element.country,
                    dateEvent: element.dateEvent,
                    game: element.game,
                    id: element.id,
                    isCheck: element.isCheck,
                    isWarning: element.isWarning,
                    lineBetting: element.lineBetting,
                    option: element.option,
                    order: element.order,
                    scoreChoose: pointUpdate + " " + oddsUpdate,
                    stage: element.stage,
                    teamChoose: element.teamChoose,
                    tournament: element.tournament,
                    change: (pointUpdate + " " + oddsUpdate) !== element.scoreChoose ? true : false,
                })
            } else {
                listNewParlay.push(element)
            }
        });
        if (checkChange > 0) {
            // setHiddenBetPlaceSoccer(true)
        }
        setListaddStrightBetsSlipSoccer(listNew)
        setListParlayBetsSlipSoccer(listNewParlay)
    }

    const [listAllGameLive, setListAllGameLive] = useState<any[]>([])
    const listTournamentRef = useRef<any[]>([])
    // const listTournamentPrematchRef = useRef<any[]>([])
    const checkLoadFirstTimeLiveGame = useRef<any>()
    const arraySocketPrematch = useRef<any>()
    useEffect(() => {
        const socketInstance = io(API_URL_SOCCER);
        setSocket(socketInstance);
        socketInstance.on('connect', () => {
            console.log('Connected to server live');
        });
        socketInstance.on('tournaments-live-soccer', async (data) => {
            const arrayOfData = data.map((obj: any) => obj.key);
            const arrayOfTournament = listTournamentSoccerNewLive.map((obj: any) => obj.key);
            if (!arraysEqual(arrayOfData, arrayOfTournament)) {
                listTournamentRef.current = data

            }
        });
        socketInstance.on('message-soccer', async (data) => {
            setListAllGameLive(data)
            checkLoadFirstTimeLiveGame.current = 1
        });

        socketInstance.on('all-tournament-soccer', async (data) => {
            setListTournamentSoccerNewSideBar(data)
        });

        socketInstance.on('message-prematch-soccer', (data: any) => {
            var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
            var dataAfter = data.filter((item: any) => ((new Date(item?.realKickoffTime).getTime() > new Date(new Date().toLocaleString('en', { timeZone: timeZone })).getTime() || !item?.realKickoffTime)))
            var arr = dataAfter.map((item: any) => ({
                ...item,
                showTime: new Date(item.realKickoffTime).getTime(),
                showTimeNow: new Date().getTime(),
                subtractTimeClient: new Date(item.realKickoffTime).getTime() - new Date().getTime()
            }))
            arraySocketPrematch.current = arr
        });

        // socketInstance.on('tournaments-prematch-soccer', async (data) => {
        //     const arrayOfData = data.map((obj: any) => obj.key);
        //     const arrayOfTournament = listTournamentSoccerNew.map((obj: any) => obj.key);
        //     if (!arraysEqual(arrayOfData, arrayOfTournament)) {
        //         listTournamentPrematchRef.current = data
        //     }

        // });

        return () => {
            if (socketInstance) {
                socketInstance.disconnect();
            }
        };
    }, []);


    useEffect(() => {

    }, [arraySocketPrematch.current])


    useEffect(() => {
        if (listDateFilterSoccer && listDateFilterSoccer[0] && listDateFilterSoccer[0].value && dateFilterSoccer === listDateFilterSoccer[0].value) {
            if (listDateFilterSoccer.length) {
                // if()
                if (!listChooseGameSoccer[1]) {
                    listDateFilterSoccer[0].totalMatch = arraySocketPrematch.current.length
                } else {
                    listDateFilterSoccer[0].totalMatch = arraySocketPrematch.current.filter((item: any) => (item.tournament.tournamentName === listChooseGameSoccer[1])).length
                }
            }
        }
        if (dateFilterSoccer === "all" && keyGameTabSoccer !== 'gameOnline') {
            var listUpdate = []
            //xóa game ở danh sách game
            if (listChooseBetSoccer.length > 0) {
                // if (keyBetTabsSoccer === '1') {
                if (listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer.length > 0) {
                    listAddStrightBetsSlipSoccer.map((item, itemIndex) => {
                        if (formatDateNow() === formatDate(item.dateEvent)) {
                            var listIdGameSocket = arraySocketPrematch.current.map((item: any) => item.id)
                            if (!listIdGameSocket.includes(item.idGame)) {
                                removeEndOfGamePrematch(Number(item.idGame))
                            }
                        }
                    })
                }
                // } else if (keyBetTabsSoccer === '2') {
                if (listParlayBetsSlipSoccer && listParlayBetsSlipSoccer.length > 0) {
                    listParlayBetsSlipSoccer.map((item, itemIndex) => {
                        if (formatDateNow() === formatDate(item.dateEvent)) {
                            var listIdGameSocket = arraySocketPrematch.current.map((item: any) => item.id)
                            if (!listIdGameSocket.includes(item.idGame)) {
                                removeEndOfGamePrematch(Number(item.idGame))
                            }
                        }
                    })
                }
                // }
            }
        }
    }, [arraySocketPrematch.current])

    useEffect(() => {
        if (listChooseGameSoccer.length === 0) {
            setListTournamentSoccerNewLive(listTournamentRef.current)
        } else {
            setListTournamentSoccerNewLive(listTournamentRef.current.filter((item: any) => (item.key === listChooseGameSoccer[0])))
        }
    }, [listTournamentRef.current]);

    const checkFirstTime = useRef(0)
    useEffect(() => {
        if (listChooseGameSoccer.length === 0) {
            setTotalGameliveSoccer(listAllGameLive.length)
            // && checkLoadFirstTimeLiveGame !== 0
            if (selectTournament === "") {
                if (listAllGameLive.length === 0 && checkFirstTime.current === 0 && checkLoadFirstTimeLiveGame.current !== 0 && checkLoadFirstTimeLiveGame.current !== undefined) {
                    handleRadioChangeSoccer("gameOffline")
                    setDateFilterSoccer("all")
                    gAllTournamentSoccer("allgame")
                    checkFirstTime.current = 1
                }
            } else {
                if (listAllGameLive.filter(item => item.tournament.tournamentName === selectTournament).length === 0 && checkFirstTime.current === 0 && checkLoadFirstTimeLiveGame.current !== 0 && checkLoadFirstTimeLiveGame.current !== undefined) {
                    handleRadioChangeSoccer("gameOffline")
                    setDateFilterSoccer("all")
                    gAllTournamentSoccer("allgame")
                    checkFirstTime.current = 1
                }
            }

        } else {
            setTotalGameliveSoccer(listAllGameLive.filter((item) => (item.tournament.tournamentName === listChooseGameSoccer[1])).length)
        }
        if (listAddStrightBetsSlipSoccer.length > 0 && listAllGameLive.length > 0) {
            listAddStrightBetsSlipSoccer.forEach(element => {
                var check = 0
                var blockBetslip = 0
                listAllGameLive.forEach(elementSocket => {
                    if (element.idGame === elementSocket.id) {
                        check = 1
                    }
                    elementSocket.odds.forEach((elementOddsSocket: any) => {
                        if (element.id === elementOddsSocket.id) {
                            elementOddsSocket.prices.forEach((elementPricesSocket: any) => {
                                if (element.lineBetting === "OddEven") {
                                    if (element.option === (elementPricesSocket.option === 'a' ? "Even" : "Odd")) {
                                        blockBetslip = 1
                                    }
                                } else {
                                    if (element.option === elementPricesSocket.option) {
                                        blockBetslip = 1
                                    }
                                }

                            });
                        }
                    });

                });
                if (keyGameTabSoccer === "gameOnline") {
                    setTimeout(() => {
                        if (check === 0 || blockBetslip === 0) {
                            removeEndOfGame(element.idGame)
                        }
                    }, 100)
                }

            });
        }
        if (selectTournament === "") {
            if (keyGameTabSoccer === 'gameOnline' && listAllGameLive.length === 0 && checkFirstTime.current === 1 && checkLoadFirstTimeLiveGame.current !== 0 && checkLoadFirstTimeLiveGame.current !== undefined) {
                //chuyển qua tab all khi hết live
                handleRadioChangeSoccer("gameOffline")
                setDateFilterSoccer("all")
                gAllTournamentSoccer("allgame")
            }
        } else {
            if (keyGameTabSoccer === 'gameOnline' && listAllGameLive.filter(item => item.tournament.tournamentName === selectTournament).length === 0) {
                //chuyển qua tab all khi hết live
                handleRadioChangeSoccer("gameOffline")
                setDateFilterSoccer("all")
                gAllTournamentSoccer("allgame")
            }
        }
    }, [listAllGameLive])

    const removeEndOfGame = (idGame: string | number | undefined) => {
        if (keyGameTabSoccer === 'gameOnline') {
            setListChooseBetSoccer([])
            setListaddStrightBetsSlipSoccer([])
        } else {
            const updatedList = listChooseBetSoccer.filter((bet) => !(bet.idGame === idGame))
            setListChooseBetSoccer(updatedList)

            const updatedListStraight = listAddStrightBetsSlipSoccer.filter((bet) => !(bet.idGame === idGame))
            setListaddStrightBetsSlipSoccer(updatedListStraight)
        }
    }
    const removeEndOfGamePrematch = useThrottle((idGame: number) => {
        const updatedList = listChooseBetSoccer.filter((bet) => !(bet.idGame === idGame))
        setListChooseBetSoccer(updatedList)

        const updatedListStraight = listAddStrightBetsSlipSoccer.filter((bet) => !(bet.idGame === idGame))
        setListaddStrightBetsSlipSoccer(updatedListStraight)

        const updatedListParlay = listParlayBetsSlipSoccer.filter((bet) => !(bet.idGame === idGame))
        setListParlayBetsSlipSoccer(updatedListParlay)
        setShowModalBetsSoccer(false)
    }, 50)

    const blockGameID = useThrottle((idGame: string | number | undefined) => {
        if (listAddStrightBetsSlipSoccer.length > 0 || listChooseBetSoccer.length > 0) {
            setShowModalBetsSoccer(false)
            const updatedList = listChooseBetSoccer.filter((bet) => !(bet.idGame === idGame))
            setListChooseBetSoccer(updatedList)

            const updatedListStraight = listAddStrightBetsSlipSoccer.filter((bet) => !(bet.idGame === idGame))
            setListaddStrightBetsSlipSoccer(updatedListStraight)
        }
    }, 1000)

    const checkUpdateStrightBetsSlip = useDebounce((id: number | string, scoreChoose: string, option: string, homeScore?: number, awayScore?: number) => {
        setUpdateOdds(true)
        var totalAmountTmp = listAddStrightBetsSlipSoccer.reduce((accumulator, currentItem) => {
            return currentItem.isCheck ? accumulator + currentItem.amount : accumulator;
        }, 0);
        const updatedList = listAddStrightBetsSlipSoccer?.map(item => {
            if (item.id === id && item.option === option) {
                return { ...item, scoreChoose: scoreChoose, homeScore: homeScore, awayScore: awayScore, win: getPiceWinStright(totalAmountTmp, item.scoreChoose) };
            }
            return item;
        });
        setListaddStrightBetsSlipSoccer(updatedList)
        if (listBetStrightSoccer.length) {
            const updatedListBet = listBetStrightSoccer?.map(item => {
                if (item.id === id && item.option === option) {
                    return { ...item, scoreChoose: scoreChoose, homeScore: homeScore, awayScore: awayScore, win: getPiceWinStright(totalAmountTmp, item.scoreChoose) };
                }
                return item;
            });
            setListBetStrightSoccer(updatedListBet)
        }
    }, 500)

    const checkUpdateStrightBetsSlipPrematch = useDebounce(async (id: number | string, scoreChoose: string, option: string, homeScore?: number, awayScore?: number) => {
        setUpdateOdds(true)
        if (dateFilterSoccer === listDateFilterSoccer[0].value) {
            if (keyBetTabsSoccer === "1") {
                var totalAmountTmp = listAddStrightBetsSlipSoccer.reduce((accumulator, currentItem) => {
                    return currentItem.isCheck ? accumulator + currentItem.amount : accumulator;
                }, 0);
                const updatedList = listAddStrightBetsSlipSoccer?.map(item => {
                    if (item.id === id && item.option === option) {
                        return { ...item, scoreChoose: scoreChoose, homeScore: homeScore, awayScore: awayScore, win: getPiceWinStright(totalAmountTmp, item.scoreChoose) };
                    }
                    return item;
                });
                setListaddStrightBetsSlipSoccer(updatedList)
                updateBetStrightSoccer(updatedList)
            } else {
                const updatedList = listParlayBetsSlipSoccer?.map(item => {
                    if (item.id === id && item.option === option) {
                        return { ...item, scoreChoose: scoreChoose, homeScore: homeScore, awayScore: awayScore, win: getPiceWinStright(totalAmountTmp, item.scoreChoose) };
                    }
                    return item;
                });
                const updatedListChooseBetSoccer = listChooseBetSoccer?.map(item => {
                    if (item.id === id && item.option === option) {
                        return { ...item, scoreChoose: scoreChoose };
                    }
                    return item;
                });
                await setListParlayBetsSlipSoccer(updatedList)
                await setListChooseBetSoccer(updatedListChooseBetSoccer)
            }
        }
    }, 500)

    useEffect(() => {
        setListChooseBetSoccer([])
        setListaddStrightBetsSlipSoccer([])
        setListParlayBetsSlipSoccer([])
        if (keyGameTabSoccer === 'gameOnline') {
            setKeyBetTabsSoccer('1')
        }
    }, [keyGameTabSoccer])
    // () => setKeyBetTabsSoccer
    const changeKeyBetTabsSoccer = (e: any) => {
        setKeyBetTabsSoccer(e)
        // if (e === "1") {
        //     setListChooseBetSoccer([])
        //     setListaddStrightBetsSlipSoccer([])
        //     setListParlayBetsSlipSoccer([])
        // }
        // setListChooseBetSoccer([])
        // setListaddStrightBetsSlipSoccer([])
        // setListParlayBetsSlipSoccer([])
    }
    const [currentIndex, setCurrentIndex] = useState(0);
    const itemsToShow = 8;

    const nextSlide = () => {
        if (currentIndex < listTournamentSoccerNewSideBar.length - itemsToShow) {
            setCurrentIndex(currentIndex + itemsToShow);
        }
    };

    const prevSlide = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - itemsToShow);
        }
    };

    const dataTournament_2: homePage_V2Api.InfoTournamentSoccer_V2[] = listTournamentSoccerNewSideBar;

    const heightTournament = dataTournament_2.slice(currentIndex, currentIndex + itemsToShow).length > 4

    return (
        <>
            {typeGame === "soccer" && (
                <Flex gap="middle" wrap="wrap" className='h-full'>
                    <Layout className='w-full h-full'>
                        <Header className='fixed z-20 top-0 w-full !p-0 !h-[56px] text-center headTab !bg-[#131620] flex items-center border-b border-[#202531]'>
                            <HeadNavi
                                tournaments={tournamentsSoccer}
                                setTournaments={handelChooseTournamentsSoccer}
                            />
                        </Header>
                        <Content className='bg-[#131620]  overflow-x-hidden !min-h-[100vh] pt-[34px] pr-[20px] pb-[34px] pl-[20px] text-center flex flex-col items-center'>
                            <ModalConfirmBet
                                status={statusModalConfirmBetSoccer}
                                showModal={showModalConfirmBetSoccer}
                                setShowModal={setShowModalConfirmBetSoccer}
                                handelConfirm={() => console.log(111111111111)}
                            />
                            <ContinueBetting
                                showModal={false}
                                setShowModal={setShowModalWarningSoccer}
                                handelConfirm={cMoneyBettingSoccer}
                            />
                            <DeleteAll
                                showModal={false}
                                setShowModal={setShowModalWarningSoccer}
                                handelConfirm={cMoneyBettingSoccer}
                            />
                            <NotEnoughModal
                                showModal={false}
                                setShowModal={setShowModalWarningSoccer}
                            />
                            <NotEnoughModal
                                showModal={false}
                                setShowModal={setShowModalWarningSoccer}
                            />
                            <ModalWarning
                                showModal={showModalWarningSoccer}
                                setShowModal={setShowModalWarningSoccer}
                            />

                            <ModalBets
                                showModalBets={showModalBetsSoccer}
                                keyBetTabs={keyBetTabsSoccer}
                                totalAmount={totalAmountStrightSoccer}
                                totalAmountParlay={totalAmountParlaySoccer}
                                setShowModalBets={setShowModalBetsSoccer}
                                handelBet={cMoneyBettingSoccer}
                            />

                            <ModalBillSoccer
                                showModalBill={showModalBillSoccer}
                                totalAmount={totalAmountStrightSoccer}
                                billCode={billCode}
                                listBillStright={listBillStrightSoccer}
                                listBillParlay={listBillParlaySoccer}
                                setShowModalBill={handleCloseModalBillSoccer}
                            />
                            <div className='fixed z-10 top-[56px] bg-[#131620] pt-[30px] pb-[30px] w-full flex justify-center'>
                                <Banner
                                    sportId={1}
                                />
                            </div>
                            <div className="bg-[#131620] fixed z-50 top-[224px] !w-[1080px] pl-3 pr-7 flex flex-col h-fit">
                                <div className='bg-gradient-A98F34 w-full py-2 flex justify-center items-center gap-2 border border-[#202531] border-b-none rounded-tl-xl rounded-tr-xl'>
                                    <img src={leaf_1} alt="" />
                                    <span className='uppercase text-lg fw-[400] text-[#E4E7F1] '>tournament EVENT</span>
                                    <img src={leaf_2} alt="" />
                                </div>
                                <div className='flex justify-between h-full border border-[#202531] border-t-none rounded-bl-xl rounded-br-xl'>
                                    <div className={`bg-[#262627] flex justify-center items-center py-7 px-5 rounded-bl-xl cursor-pointer ${currentIndex === 0 ? "!hidden" : ""}`}
                                        onClick={prevSlide}
                                    >
                                        <img src={arrow_left} alt="" />
                                    </div>
                                    <div className='grid grid-cols-4 w-full'>
                                        {
                                            dataTournament_2.slice(currentIndex, currentIndex + itemsToShow).map((item) => (
                                                <div key={item.key} className='w-full flex items-center gap-2 border-r border-[#202531] py-3 pl-5 min-h-[72px]'
                                                    onClick={() => {
                                                        handleMenuSelectSoccer_V2?.(
                                                            item.tournament,
                                                            item.key
                                                        )
                                                    }
                                                    }>
                                                    <div className='w-[28px] h-[20px] bg-[#FF4A59] text-white text-[14px] rounded fw-[600]'>
                                                        HOT
                                                    </div>
                                                    <span className={`text-base fw-[400] text-start line-clamp-2 ${item.key === listChooseGameSoccer[0]
                                                        ? "text-[#D4B962] "
                                                        : "text-[#C1C9E1]"
                                                        }`}>{item.tournament}</span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className={`bg-[#262627] flex justify-center items-center py-7 px-5 rounded-br-xl cursor-pointer ${dataTournament_2.length - currentIndex <= itemsToShow ? "!hidden" : ""}`}
                                        onClick={nextSlide}
                                    >
                                        <img src={arrow_right} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className={`pt-9 relative !w-[1056px] flex pr-8 ${heightTournament ? "mt-[250px]" : "mt-[165px]"}`}>
                                <div className={`sideBar align-top bottom-0 h-[300px] fixed ${heightTournament ? "top-[435px]" : "top-[360px]"}`}>
                                    <div className='flex flex-col border border-[#202531] rounded-2 bg-[#131620] mt-3 rounded-[6px] w-full'>
                                        <div className='bg-gradient-A98F34 flex justify-between h-[38px] px-3 w-full'>
                                            <div className='flex items-center gap-2 w-full'>
                                                <img src={Dollar} alt="" className="w-[30px] h-[30px]" />
                                                <span className='fw-[700] text-base text-white uppercase'>Balance</span>
                                                <HeadRight></HeadRight>
                                            </div>
                                        </div>
                                        <span className='flex justify-between p-3'>
                                            <div className="flex items-center gap-2">
                                                <img src={start} alt="Crown" className='w-[35px] h-[35px]' />
                                                <p className="fw-[700] text-base text-white uppercase">Bet Slip</p>
                                            </div>
                                            <div className='flex items-center gap-1 pr-3'>
                                                <div className={`${listAddStrightBetsSlipSoccer.length > 0 || listParlayBetsSlipSoccer.length > 0 ? 'flex items-center gap-1' : 'hidden '}`}
                                                    onClick={clearAllStrightBetsSlipSoccer}
                                                >
                                                    <img src={Trash} alt="" />
                                                    <span className='text-base text-white uppercase fw-[500]'>Remove All</span>
                                                </div>

                                                <span className={`h-[30px] w-[30px] rounded-[50%]   text-base flex items-center justify-center ${listAddStrightBetsSlipSoccer.length === 0 ? 'bg-[#202531] text-white' : 'bg-[#D4B962] text-[#131620]'}`}>
                                                    {keyBetTabsSoccer === '1' ? listAddStrightBetsSlipSoccer.length.toString() : listParlayBetsSlipSoccer.length.toString()}
                                                </span>
                                            </div>
                                        </span>

                                        <div className={`tab-bet-soccer ${listAddStrightBetsSlipSoccer.length === 0 && listParlayBetsSlipSoccer.length === 0 ? '' : 'hidden'}`}>
                                            <Tabs items={menu}
                                                onChange={onChangeSoccer}
                                                activeKey={keyBetTabsSoccer}

                                            />
                                            <EmtyBetSlip activeKey={keyBetTabsSoccer} />
                                        </div>
                                        <div className={`${listAddStrightBetsSlipSoccer.length === 0 && listParlayBetsSlipSoccer.length === 0 ? '' : 'hidden'} border-t border-[#202531] flex flex-col gap-3 px-6 pb-5`}>
                                            <div className='flex justify-between pt-3'>
                                                <div className='flex flex-col gap-4'>
                                                    <span className='text-base fw-[400] text-[#E4E7F1]'>
                                                        Total stake
                                                    </span>
                                                    <span className='text-base fw-[400] text-[#E4E7F1]'>-</span>
                                                </div>
                                                <div className='flex flex-col gap-4'>
                                                    <span className='text-base fw-[400] text-[#E4E7F1]'>
                                                        Total win
                                                    </span>
                                                    <span className='text-base fw-[400] text-[#E4E7F1]'>-</span>
                                                </div>
                                            </div>
                                            <button className={`font-bold rounded-[2px]  uppercase w-full  py-[10px] ${listAddStrightBetsSlipSoccer.length > 0 ? 'bg-[#D4B962] text-[#20273D]' : 'bg-[#E4E7F1] text-[#666E97]'}`}>
                                                Place bet
                                            </button>
                                        </div>
                                        <div className={`${listAddStrightBetsSlipSoccer.length === 0 && listParlayBetsSlipSoccer.length === 0 ? '' : 'hidden'}`}>

                                        </div>
                                        <div
                                            hidden={!listAddStrightBetsSlipSoccer.length && !listParlayBetsSlipSoccer.length}
                                        >
                                            <BetTabsSoccer
                                                menu={menu}
                                                keyBetTabs={keyBetTabsSoccer}
                                                keyGameTab={keyGameTabSoccer}
                                                chooseElement=''
                                                totalStake={totalStakeSoccer}
                                                listAddStrightBetsSlip={listAddStrightBetsSlipSoccer}
                                                listParlayBetsSlip={listParlayBetsSlipSoccer}
                                                setKeyBetTabs={(e) => changeKeyBetTabsSoccer(e)}
                                                rStrightBetsSlipByIndex={rStrightBetsSlipByIndexSoccer}
                                                clearAll={clearAllStrightBetsSlipSoccer}
                                                setListaddStrightBetsSlip={setListaddStrightBetsSlipSoccer}
                                                setListParlayBetsSlip={setListParlayBetsSlipSoccer}
                                                setTotalStake={setTotalStakeSoccer}
                                            />
                                        </div>
                                    </div>

                                    <TotalStakeComponentV2

                                        keyBetTabs={keyBetTabsSoccer}
                                        showTotalBet={showTotalBetSoccer}
                                        showTotalStake={showTotalStakeSoccer}
                                        isWarningBetParlay={isWarningBetParlaySoccer}
                                        totalOddsParlay={totalOddsParlaySoccer}
                                        totalAmountParlay={totalAmountParlaySoccer}
                                        numberStake={totalStakeSoccer}
                                        listAddStrightBetsSlip={listAddStrightBetsSlipSoccer}
                                        listParlayBetsSlip={listParlayBetsSlipSoccer}

                                        handleClick={handleAllClickSoccer}
                                        handleNumberClick={handleAllNumberClickSoccer}
                                        handleDeleteClick={handleAllDeleteClickSoccer}
                                        showHideTotalStake={showHideTotalStakeSoccer}
                                        handleCheckQuantityMoneyStraight={handleCheckQuantityMoneyStraight}
                                        handleCheckBetStright={handleCheckBetStrightSoccer}
                                        handleCheckBetParlay={handleCheckBetParlaySoccer}
                                        hiddenBetPlaceSoccer={hiddenBetPlaceSoccer}
                                    />

                                </div>
                                <Content className='inline-block align-top ml-[320px] pr-8'>
                                    <div className={`fixed z-20 w-[760px] ${heightTournament ? "top-[430px]" : "top-[355px]"}`}>
                                        <div className='bg-[#131620] pt-4 pr-10'>
                                            <div className='flex flex-col border-[#202531] border-t rounded-2 rounded-[6px] '>
                                                <div className='bg-gradient-A98F34 flex justify-between items-center h-[38px] px-3'>
                                                    <span className='fw-[700] text-base text-white uppercase pl-6'>Game list</span>
                                                    <div className='flex items-center gap-[10px] pl-[10px] border-l border-[#202531]' onClick={resetBet}>
                                                        <img src={reload} alt="" className='h-[20px]' />

                                                        {time && time > 0
                                                            ?
                                                            (<span className='text-white'>{time}</span>)
                                                            :
                                                            (<span className='text-base fw-[400] uppercase text-white'>Reload</span>)}
                                                    </div>
                                                </div>
                                                <DayTime_V2
                                                    listDateFilterSoccer={listDateFilterSoccer}
                                                    dateFilterSoccer={dateFilterSoccer}
                                                    changeDateFilter={changeDateFilter}
                                                    keyGameTab={keyGameTabSoccer}
                                                    totalGame={totalGameSoccer}
                                                    totalGameOnLine={totalGameLiveSoccer}
                                                    totalAllGame={totalAllGameSoccer}
                                                    onRadioChange={handleRadioChangeSoccer}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='top-[1000px] mt-[250px] w-[760px] '>
                                        <div className='w-full flex flex-col  rounded-sm gap-3 pr-10'>
                                            {keyGameTabSoccer === "gameOffline" && listTournamentSoccerNew && listTournamentSoccerNew.map((item, i) => (
                                                <LazyLoad height={200} key={i}>
                                                    <div
                                                        key={item.tournament}
                                                    >
                                                        <Collapse
                                                            key={item.tournament}
                                                            defaultActiveKey={[i]}
                                                            expandIconPosition='end'
                                                            className=' w-full collapse-league'

                                                            expandIcon={
                                                                (expanded) => (
                                                                    expanded.isActive ? (
                                                                        <div className='w-full flex justify-center gap-2 '>
                                                                            <span className="uppercase text-base fw-[400] !text-[#E4E7F1]">Collapse</span>
                                                                            <img src={Collapse_img} alt="" />
                                                                        </div>
                                                                    ) : (
                                                                        <div className='w-full flex justify-center gap-2 '>
                                                                            <span className="uppercase text-base fw-[400] !text-[#E4E7F1]">expand</span>
                                                                            <img src={Expand} alt="" />
                                                                        </div>
                                                                    )
                                                                )


                                                            }
                                                            items={[{
                                                                key: i,
                                                                label: <div className='text-[#D4B962] fw-[500] text-base flex items-center uppercase'>
                                                                    <div className='h-[21px] bg-[#42391A] rounded-tl-md rounded-bl-md w-[10px]'>
                                                                    </div>
                                                                    {item.tournament}
                                                                </div>,
                                                                children:
                                                                    < GameSoccer_V2
                                                                        // loadTournament={loadTournament}
                                                                        // setLoadTournament={() => setLoadTournament('')}
                                                                        keyBetTabsSoccer={keyBetTabsSoccer}
                                                                        tournament={item.tournament}
                                                                        item={item}
                                                                        listChooseBet={listChooseBetSoccer}
                                                                        chooseBetSoccer={checkAddStrightBetsSlipSoccer}
                                                                        listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                                                        listParlayBetsSlipSoccer={listParlayBetsSlipSoccer}
                                                                        countTotalSoccer={countTotalSoccer}
                                                                        listDateFilterSoccer={listDateFilterSoccer}
                                                                        dateFilterSoccer={dateFilterSoccer}
                                                                        resetBetGame={resetBetGame}
                                                                        updateBetGameReset={updateBetGameReset}
                                                                        checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlipPrematch}
                                                                        removeEndOfGamePrematch={removeEndOfGamePrematch}
                                                                        setTotalGameSoccer={setTotalGameSoccer}
                                                                        typeListGameSoccer={typeListGameSoccer}
                                                                    />
                                                            }]}
                                                        />
                                                    </div>

                                                </LazyLoad>

                                            ))}
                                            {
                                                keyGameTabSoccer === "gameOnline" && listTournamentSoccerNewLive && listTournamentSoccerNewLive.map((item, i) => (
                                                    // <LazyLoad key={item.tournament} placeholder={<Loading />}>
                                                    <LazyLoad height={200} key={i}>
                                                        <div
                                                            key={item.tournament}
                                                        >

                                                            <Collapse
                                                                key={`Live-${item.tournament}`}

                                                                defaultActiveKey={[`Live-${item.tournament}`]}
                                                                expandIconPosition='end'
                                                                className=' w-full collapse-league'

                                                                expandIcon={
                                                                    (expanded) => (
                                                                        expanded.isActive ? (
                                                                            <div className='w-full flex justify-center gap-2 '>
                                                                                <span className="uppercase text-xs fw-[400] !text-[#E4E7F1]">Collapse</span>
                                                                                <img src={Collapse_img} alt="" />
                                                                            </div>
                                                                        ) : (
                                                                            <div className='w-full flex justify-center gap-2 '>
                                                                                <span className="uppercase text-xs fw-[400] !text-[#E4E7F1]">expand</span>
                                                                                <img src={Expand} alt="" />
                                                                            </div>
                                                                        )
                                                                    )


                                                                }
                                                                items={[{
                                                                    key: `Live-${item.tournament}`,
                                                                    label: <div className='text-[#D4B962] fw-[500] text-sm flex items-center uppercase'>
                                                                        <div className='h-[21px] bg-[#42391A] rounded-tl-md rounded-bl-md w-[10px]'>
                                                                        </div>
                                                                        {item.tournament}
                                                                    </div>,
                                                                    children:
                                                                        <GameSoccerLive_V2
                                                                            tournament={item.tournament}
                                                                            item={item}
                                                                            listChooseBet={listChooseBetSoccer}
                                                                            listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                                                            chooseBetSoccer={checkAddStrightBetsSlipSoccer}
                                                                            countTotalSoccer={countTotalSoccerLive}
                                                                            checkUpdateOdds={checkUpdateOddsSoccer}
                                                                            checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                                                            blockGameID={blockGameID}
                                                                        />
                                                                }]}
                                                            />


                                                        </div>
                                                    </LazyLoad>
                                                ))}

                                        </div>
                                    </div>
                                </Content>
                            </div>
                        </Content>
                    </Layout>
                </Flex>
            )}
        </>
    )
}
export default SoccerMainNew;